<template>
  <div id="personalCheckin" class="Content-Page">
    <!-- <canvas id="canvas" width="750" height="500">your canvas loads here</canvas> -->
    <canvas id="canvas" width="750" height="0">your canvas loads here</canvas>
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Personal Checkin</div>
      </div>
    </div>
    <div id="flightSeat" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">Flight No.</span>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field
                  label="Ref No."
                  class="hideMessage"
                  v-model="ref_id"
                  disabled
                ></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field
                  label="Flight No."
                  class="hideMessage"
                  v-model="flightno"
                  disabled
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div id="selectSeat" class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">Seat</span>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <div class="B-carddetail">
                  <v-btn
                    class="theme-btn-even btn-seat2 center Bsize190"
                    @click="OpenDialogSelectSeat()"
                    v-if="seatNo != null"
                    :disabled="!(userdata.r_id == 5)"
                  >
                    <!-- <span class="I-seat2"></span> -->
                    <span class="I-seat"></span>
                    <span class="T-size-18">Seat {{ seatNo }}</span>
                  </v-btn>
                  <v-btn
                    v-if="seatNo == null"
                    :disabled="!(userdata.r_id == 5)"
                    class="theme-btn-even btn-seat center Bsize190"
                    @click="OpenDialogSelectSeat()"
                  >
                    <span class="I-seat"></span>
                    <span class="T-size-18">Select Seat</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="personalDetail" class="wrap-Main">
      <div class="box-S2">
        <div id="PersonDetail" class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">Personal Details</span>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="B-passenger">
            <div class="b-person">
              <div class="b-display">
                <div class="B-display"></div>
              </div>
              <div class="b-name-company">
                <p class="t-name T-size-20">
                  {{ u_firstname }} {{ u_lastname }}
                  <span
                    class="t-company T-size-16"
                    style="color: white; background: red"
                    @click="dialogRemark = true"
                    >Remark</span
                  >
                </p>
                <!-- <div class="t-company">Company : {{c_name}}</div> -->
                <!-- Edit -->
                <div class="t-company">Customer : {{ o_name }}</div>
                <div></div>
              </div>
            </div>

            <!-- Dialog Input Remark Firt Time Offshore -->
            <v-dialog v-model="dialogRemark" persistent max-width="400">
              <v-card id="dialog" class="TF type2">
                <div class="b-content">
                  <div class="B-dialogform">
                    <div class="t-namepage">Remark</div>
                    <div class="b-form">
                      <v-text-field
                        label="Remark (First Time Offshore)"
                        v-model="FirstTimeOffshore"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="box-btn">
                    <v-btn
                      class="theme-btn-even btn-cancel Bsize100"
                      text
                      @click="dialogRemark = false"
                    >
                      <span class="T-size-18">cancel</span>
                    </v-btn>
                    <v-btn
                      :disabled="!(userdata.r_id == 5)"
                      class="theme-btn-even btn-save Bsize100"
                      text
                      @click="SaveRemarkFirstTimeOffshore()"
                    >
                      <span class="T-size-18">Save</span>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-dialog>
            <!-- Dialog Input Remark Firt Time Offshore -->

            <div class="b-detail">
              <div class="t-title">Personal Data</div>
              <div class="box-S4 flex-between-center t-detail">
                <div class="bL">
                  <span class="T-size-14">Gender</span>
                  <p class="T-size-16">{{ u_gender }}</p>
                </div>
                <div class="bR">
                  <span class="T-size-14">Telephone</span>
                  <p class="T-size-16">{{ u_telephone }}</p>
                </div>
              </div>
            </div>
            <div class="b-detail">
              <div class="t-title">Emergency Contact</div>
              <div class="box-S4 flex-between-center t-detail">
                <div class="bL">
                  <span class="T-size-14">Name</span>
                  <p class="T-size-16" v-if="u_emergency_contact != null">
                    {{ u_emergency_contact }} ({{ u_emergency_relation }})
                  </p>
                </div>
                <div class="bR">
                  <span class="T-size-14">Telephone</span>
                  <p class="T-size-16">{{ u_emergency_telephone }}</p>
                </div>
              </div>
            </div>
            <div class="b-detail">
              <div class="t-title">Certification Validation</div>
              <div class="box-S4 flex-between-center t-detail">
                <div class="bL">
                  <span class="T-size-14">Safety Training</span>
                  <p class="T-size-16">
                    {{ u_safety_training | yyyyMMdd }} [{{
                      Dayleft_safety_training
                    }}
                    Day Left]
                  </p>
                </div>
                <div class="bR">
                  <span class="T-size-14">Medical Check Up</span>
                  <p class="T-size-16">
                    {{ u_medical_checkup | yyyyMMdd }} [{{
                      Dayleft_medical_checkUp
                    }}
                    Day Left]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div id="bodyWeight" class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">Body Weight</span>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <div class="B-carddetail">
                  <v-btn
                    :disabled="!(userdata.r_id == 5)"
                    class="theme-btn-even btn-seat2 center Bsize190"
                    @click="dialogInputBodyWeight = true"
                    v-if="p_body_weight != null && p_body_weight != ''"
                  >
                    <v-icon>mdi-weight-lifter</v-icon>
                    <span class="T-size-18">{{ p_body_weight }} Kg.</span>
                  </v-btn>
                  <v-btn
                    v-if="p_body_weight == null || p_body_weight == ''"
                    :disabled="!(userdata.r_id == 5)"
                    class="theme-btn-even btn-seat center Bsize190"
                    @click="dialogInputBodyWeight = true"
                  >
                    <v-icon>mdi-weight-lifter</v-icon>
                    <span class="T-size-18">Body Weight</span>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- Button Input Body Weight -->

            <!-- Dialog Input Body Weight -->
            <v-dialog
              v-model="dialogInputBodyWeight"
              persistent
              max-width="400"
            >
              <v-card id="dialog" class="TF type2">
                <div class="b-content">
                  <div class="B-dialogform">
                    <div class="t-namepage">Body Weight</div>
                    <div class="b-form">
                      <v-text-field
                        :disabled="!(userdata.r_id == 5)"
                        label="Body Weight"
                        type="number"
                        v-model="p_body_weight"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="box-btn">
                    <v-btn
                      class="theme-btn-even btn-cancel Bsize100"
                      text
                      @click="CancelSaveBodyWeigh()"
                    >
                      <span class="T-size-18">cancel</span>
                    </v-btn>
                    <v-btn
                      class="theme-btn-even btn-save Bsize100"
                      text
                      @click="SaveBodyWeight()"
                    >
                      <span class="T-size-18">Save</span>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-dialog>
            <!-- Dialog Input Body Weight -->
          </div>
        </div>
        <div id="baggageWeight" class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">Baggage</span>
            </div>
          </div>
        </div>
        <div class="B-baggageWeight-Main">
          <div
            class="B-baggageWeight"
            v-for="(item, index) in ArrlistAddBaggage"
            :key="item.Idflightbaggage"
          >
            <!-- เมืื่อวัดแอลกอฮอล ผ่านใส่ Class pass ไม่ผ่านใส่ Class nopass -->
            <div class="b-baggageWeight-name">
              <div class="T-size-18 SemiBold">
                Baggage Weight : {{ item.bg_weight }} Kg.
              </div>
            </div>
            <div class="b-action">
              <div class="b-btn">
                <v-btn
                  :disabled="!(userdata.r_id == 5)"
                  fab
                  class="btn-edit"
                  @click="EditItemBaggage(item, index)"
                >
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn
                  :disabled="!(userdata.r_id == 5)"
                  fab
                  class="primary btn-delete"
                  @click="deleteItemBaggage(item)"
                >
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even"
              :disabled="!(userdata.r_id == 5)"
              @click="addItemBaggage()"
            >
              <span class="I-create"></span>
              <span>Add Baggage</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="alcoholTemperature" class="wrap-Main">
      <div class="box-S2">
        <div id="RFID" class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">RFID</span>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain" v-if="userdata.r_id == 5">
            <!-- <div class="b-row">
              <div class="box-S4">
                <v-text-field
                  :disabled="briefCheckin == 1"
                  label="RFID Code"
                  v-model="p_rfid"
                ></v-text-field>
              </div>
            </div> -->

            <!-- Button Input RFID -->
            <div class="b-row">
              <div class="box-S4">
                <div class="B-carddetail">
                  <v-btn
                    class="theme-btn-even btn-seat2 center Bsize190"
                    @click="OpenDialogRFID()"
                    :disabled="briefCheckin == 1"
                    v-if="p_rfid != '' && p_rfid != null"
                  >
                    <v-icon>mdi-access-point</v-icon>
                    <span class="T-size-18">{{ p_rfid }} </span>
                  </v-btn>
                  <v-btn
                    v-if="p_rfid == '' || p_rfid == null"
                    :disabled="!(userdata.r_id == 5)"
                    class="theme-btn-even btn-seat center Bsize190"
                    @click="dialogInputRFID = true"
                  >
                    <v-icon style="padding-right: 3px">mdi-access-point</v-icon>
                    <span class="T-size-18">RFID (USB)</span>
                  </v-btn>

                  <p></p>
                  <v-btn
                    v-if="p_rfid == '' || p_rfid == null"
                    :disabled="!(userdata.r_id == 5)"
                    class="theme-btn-even btn-seat center Bsize190"
                    @click="RFIDScanner(1)"
                  >
                    <v-icon style="padding-right: 3px">mdi-access-point</v-icon>
                    <span class="T-size-18">CHANNEL 1</span>
                  </v-btn>
                  <p></p>
                  <v-btn
                    v-if="p_rfid == '' || p_rfid == null"
                    :disabled="!(userdata.r_id == 5)"
                    class="theme-btn-even btn-seat center Bsize190"
                    @click="RFIDScanner(2)"
                  >
                    <v-icon style="padding-right: 3px">mdi-access-point</v-icon>
                    <span class="T-size-18">CHANNEL 2</span>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- Button Input RFID -->

            <!-- Button Brief Checkin -->
            <div
              class="b-row"
              v-if="userdata.r_id == 5 && p_rfid != null && p_rfid != ''"
            >
              <div id="RFID" class="box-S4">
                <div class="B-carddetail">
                  <v-btn
                    v-if="briefCheckin == null"
                    class="theme-btn-even btn-seat center Bsize190"
                    @click="BriefCheckin(p_rfid)"
                  >
                    <v-icon>mdi-access-point-check</v-icon>
                    <span class="T-size-18">Brief Check-in</span>
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="b-row" v-if="userdata.r_id == 5">
              <div id="RFID" class="box-S4" style="text-align: center">
                <div class="B-carddetail">
                  <div v-if="briefCheckin == 1">
                    <v-icon>mdi-access-point-check</v-icon>
                    <span class="T-size-18">Brief Check-in Completed</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Button Brief Checkin -->

            <!-- Dialog Input RFID -->
            <v-dialog v-model="dialogInputRFID" persistent max-width="400">
              <v-card id="dialog" class="TF type2">
                <div class="b-content">
                  <div class="B-dialogform">
                    <div class="t-namepage">RFID</div>
                    <div class="b-form">
                      <v-text-field
                        id="txtRFID"
                        :disabled="briefCheckin == 1"
                        label="RFID Code"
                        v-model="p_rfid"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="box-btn">
                    <v-btn
                      class="theme-btn-even btn-cancel Bsize100"
                      text
                      @click="CancelSaveRFID()"
                    >
                      <span class="T-size-18">cancel</span>
                    </v-btn>
                    <v-btn
                      class="theme-btn-even btn-save Bsize100"
                      text
                      @click="SaveRFID()"
                    >
                      <span class="T-size-18">Save</span>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-dialog>
            <!-- Dialog Input RFID -->
          </div>
        </div>
      </div>

      <div class="box-S2" v-if="$route.params.o_name != 'MUBADALA'">
        <div class="box-S4">
          <div class="N-Page">
            <div class>
              <span class="T-size-24">Alcohol and Temperature Test</span>
            </div>
          </div>
        </div>
        <!-- <div class="T-size-16 ">BAYONG Conditions : Alcohol &lt; 0.02 mg. / Temperature &lt; 38 &deg;C</div> -->
        <div class="T-size-16">
          {{ c_name }} Conditions : Alcohol &lt; {{ alcoholLimit }} mg. /
          Temperature &lt; {{ tempLimit }}
          &deg;C
        </div>
        <div class="B-alcoholTemp-Main alcohol">
          <div v-for="item in ArrlistAddAlcohol" :key="item.at_id">
            <div class="B-alcoholTemp nopass" v-show="item.at_status == '0'">
              <!-- เมืื่อวัดแอลกอฮอล ผ่านใส่ Class pass ไม่ผ่านใส่ Class nopass -->
              <div class="b-alcoholTemp-name">
                <div class="box-S4 flex-between-center noPadding">
                  <div
                    class="bL T-size-18 SemiBold"
                    v-if="item.at_value_test >= 0"
                  >
                    Alcohol : {{ item.at_value_test }} mg
                  </div>
                  <div class="bL T-size-18 SemiBold" v-else>Alcohol : N/A</div>
                  <div class="bR T-size-18 SemiBold">
                    Temperature : {{ item.at_value_temp }} &deg;C
                  </div>
                </div>
                <div>Report By : {{ item.at_report_by }}</div>
                <div>
                  Date &amp; Time :
                  {{ item.at_value_date }}
                </div>
              </div>
            </div>
            <div class="B-alcoholTemp pass" v-show="item.at_status == '1'">
              <!-- เมืื่อวัดแอลกอฮอล ผ่านใส่ Class pass ไม่ผ่านใส่ Class nopass -->
              <div class="b-alcoholTemp-name">
                <div class="box-S4 flex-between-center noPadding">
                  <div
                    class="bL T-size-18 SemiBold"
                    v-if="item.at_value_test >= 0"
                  >
                    Alcohol : {{ item.at_value_test }} mg
                  </div>
                  <div class="bL T-size-18 SemiBold" v-else>Alcohol : N/A</div>
                  <div class="bR T-size-18 SemiBold">
                    Temperature : {{ item.at_value_temp }} &deg;C
                  </div>
                </div>
                <div>Report By : {{ item.at_report_by }}</div>
                <div>
                  Date &amp; Time :
                  {{ item.at_value_date }}
                </div>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even"
              v-show="
                ArrlistAddAlcohol.length < 3 &&
                ArrDataPassenger.as_id < 4 &&
                at_statusNow != true &&
                (userdata.r_id == 5 || userdata.r_id == 7)
              "
              @click="addItemAlcohol()"
            >
              <span class="I-create"></span>
              <span>Alcohol and Temperature Test</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <!-- Declare Form -->
    <!-- <DeclareForm
      v-if="
        (o_name == 'MUBADALA' &&
          ArrDataPassenger.ap_departairport_name == 'VTBU' &&
          userdata.r_id == 5) ||
        (o_name == 'MUBADALA' &&
          ArrDataPassenger.ap_departairport_name == 'VTSH' &&
          (userdata.r_id == 5 || userdata.r_id == 7))
      "
      :PassengerID="$route.params.id"
      :Fullname="fullname"
      :CompanyName="c_name"
      :Destination="$route.params.destination"
      :CheckIn="ArrDataPassenger.p_checkin"
      :CheckInDateTime="ArrDataPassenger.p_checkin_date"
      :SignatureImage="ArrDataPassenger.p_checkin_signature"
    ></DeclareForm> -->
    <!-- Declare Form -->

    <!-- Sign Signature -->
    <div id="personSignature" class="wrap-Main" v-if="userdata.r_id == 5">
      <div class="box-S4">
        <div class="N-Page">
          <div class>
            <span class="T-size-24">Signature</span>
          </div>
        </div>
      </div>
      <div class="box-S4" v-show="ArrDataPassenger.p_checkin == null">
        <div class="B-carddetail B-formMain">
          <div class="b-condition">
            <div class="box-S4">
              <div class="b-checkbox checkin">
                <v-checkbox
                  v-model="checkbox1"
                  hide-details
                  label="ข้าพเจ้าได้อ่านและเข้าใจในข้อจำกัดเกี่ยวกับสิ่งของอันตรายที่ต้องห้ามสำหรับการขนส่งทางอากาศในกระเป๋าหรือสัมภาระที่ผ่านการตรวจสอบแล้ว (I,hereby, acknowledged that I am understood and aware on the restrictions and limitations of a transportation of Dangerous Goods by air on all carried and checked-in baggage or cargo.)"
                  @change="CheckInDateTime()"
                ></v-checkbox>
                <!-- <v-checkbox
                  v-model="checkbox2"
                  hide-details
                  label=" อ้างตามประกาศฉบับที่ 7 ออกตามความใน พรบ คุ้มครองแรงงาน พศ 2541 ข้าพเจ้าขอรับรองว่า ข้าพเจ้ามีวันทำงานกลางทะเลไม่เกิน 28 วันและมีวันหยุดไม่ต่ำกว่ากึ่งหนึ่งของวันทำงานต่อเนื่อง (Refer to Ministry regulation no.7 follow Labour protect Act B.E.2541 I hereby, certify that my working days at offshore are not more than 28 days and have minimum day off not less than half of consecutive working days)"
                  @change="CheckInDateTime()"
                ></v-checkbox> -->
              </div>
            </div>
          </div>
          <div class="b-signature" v-if="this.checkbox1 == true">
            <div class="b-sign">
              <!-- ใส่ Script Sign -->
              <VueSignaturePad
                width="100%"
                height="500px"
                ref="signaturePad"
                :options="options"
              />
            </div>
          </div>
          <!-- <div class="center" v-if="this.checkbox1 == true && this.checkbox2 == true"> -->
          <div class="center" v-if="this.checkbox1 == true">
            <p>CheckIn <v-icon>mdi-check</v-icon></p>
            <p>CheckIn Date : {{ checkInDateTime | yyyyMMddHHmmAdd7Hour }}</p>
          </div>
          <!-- <div class="box-S4 flex-between-center" v-if="this.checkbox1 == true && this.checkbox2 == true"> -->
          <div class="box-S4 flex-between-center" v-if="this.checkbox1 == true">
            <div class="b-btnSave">
              <v-btn
                class="theme-btn-even btn-cancle Bsize150"
                @click="UndoSignature()"
              >
                <span class="T-size-18">Clear</span>
              </v-btn>
              <v-btn
                class="theme-btn-even btn-sign Bsize150"
                @click="SaveSignatureToDB()"
              >
                <span class="I-sign2"></span>
                <span class="T-size-18">Sign</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- Sign Signature -->

      <!--Show Img Signature -->
      <div class="box-S4" v-show="ArrDataPassenger.p_checkin == '1'">
        <div class="B-carddetail B-formMain">
          <div class="b-condition">
            <v-img
              class="center"
              max-width="750px"
              :src="ArrDataPassenger.p_checkin_signature"
            ></v-img>
          </div>
          <div style="text-align: center">
            <div>CheckIn<v-icon>mdi-check</v-icon></div>
            <div class="flex-center">
              CheckIn Date
              {{ ArrDataPassenger.p_checkin_date | yyyyMMddHHmmAdd7Hour }}
            </div>
          </div>

          <div style="text-align: center; padding-top: 20px">
            <v-btn
              class="theme-btn-even btn-save Bsize150"
              @click="PrintSignature()"
            >
              <!-- <span class="I-save"></span> -->
              <span class="T-size-18">Print</span>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="line"></div>
    </div>
    <!--Show Img Signature -->

    <!-- Show Only Alcohol Test -->
    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn
            class="theme-btn-even btn-cancle Bsize150"
            @click="Back()"
            :disabled="true"
          >
            <span class="T-size-18">Cancel</span>
          </v-btn>
          <v-btn
            :disabled="true"
            class="theme-btn-even btn-save Bsize150"
            @click="SaveAlcoholEditToDB()"
          >
            <span v-if="false" class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
        </div>
        <div class="b-btnDelete">
          <v-btn
            :disabled="!(userdata.r_id == 5)"
            class="theme-btn-even btn-delete Bsize150"
            @click="dialogOffload = true"
          >
            <span class="I-bin"></span>
            <span class="T-size-18">Offload</span>
          </v-btn>
        </div>
      </div>
    </div>
    <!-- Show Only Alcohol Test -->

    <div id="partBack" class="wrap-Main">
      <!-- <div class="line"></div> -->
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <!-- Add dialog SaveEditToDB complete Only Alcohol Test -->
    <v-dialog v-model="dialogSaveComplete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Completed</div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-ok Bsize100"
              text
              @click="GotoListCheckin()"
            >
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Add dialog SaveEditToDB complete Only Alcohol Test -->

    <!-- Add dialog SaveSignToDB complete -->
    <v-dialog v-model="dialogSignComplete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Sign Completed</div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-ok Bsize100"
              text
              @click="GotoListCheckin()"
            >
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Add dialog SaveSignToDB complete -->

    <!-- Dialog Seat -->
    <v-dialog v-model="dialogSeat" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Select Seat</div>
            <div class="b-form">
              <div class="b-seatLayout">
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[0].Status"
                  :class="seats[0].class"
                  @click="SelectSeat(0, 'A')"
                >
                  <i class="I-seatR"></i>
                  <span class="T-size-20">A</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[4].Status"
                  :class="seats[4].class"
                  @click="SelectSeat(4, 'E')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">E</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[8].Status"
                  :class="seats[8].class"
                  @click="SelectSeat(8, 'I')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">I</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[1].Status"
                  :class="seats[1].class"
                  @click="SelectSeat(1, 'B')"
                >
                  <i class="I-seatR"></i>
                  <span class="T-size-20">B</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[5].Status"
                  :class="seats[5].class"
                  @click="SelectSeat(5, 'F')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">F</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[9].Status"
                  :class="seats[9].class"
                  @click="SelectSeat(9, 'L')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">L</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[2].Status"
                  :class="seats[2].class"
                  @click="SelectSeat(2, 'C')"
                >
                  <i class="I-seatR"></i>
                  <span class="T-size-20">C</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[6].Status"
                  :class="seats[6].class"
                  @click="SelectSeat(6, 'G')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">G</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[10].Status"
                  :class="seats[10].class"
                  @click="SelectSeat(10, 'M')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">M</span>
                </v-btn>
                <!-- class="select" -->
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[3].Status"
                  :class="seats[3].class"
                  @click="SelectSeat(3, 'D')"
                >
                  <i class="I-seatR"></i>
                  <span class="T-size-20">D</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[7].Status"
                  :class="seats[7].class"
                  @click="SelectSeat(7, 'H')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">H</span>
                </v-btn>
                <v-btn
                  class="btn-seatLayout"
                  :disabled="seats[11].Status"
                  :class="seats[11].class"
                  @click="SelectSeat(11, 'N')"
                >
                  <i class="I-seatL"></i>
                  <span class="T-size-20">N</span>
                </v-btn>

                <div
                  v-if="seatNo != null"
                  class="t-title center"
                  style="color: white; padding-top: 3px; padding-bottom: 3px"
                >
                  {{ u_firstname }} {{ u_lastname }}<br />select seat
                  {{ seatNo }}.
                  <span
                    class="t-company T-size-16"
                    style="
                      color: white;
                      background: red;
                      border-radius: 10px;
                      padding-left: 10px;
                      padding-right: 10px;
                      margin-left: 5px;
                    "
                    @click="ClearSeat(seatNo)"
                    >Clear seat</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogSeat = false"
            >
              <span class="T-size-18">Close</span>
            </v-btn>
            <!-- <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="SaveSeatToArray()"
            >
              <span class="T-size-18">Save</span>
            </v-btn> -->
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog Baggage -->
    <v-dialog v-model="dialogBaggage" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">{{ modeEvent }} Baggage</div>
            <div class="b-form">
              <v-text-field
                label="Baggage Weight"
                type="number"
                v-model="bg_weight"
              ></v-text-field>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogBaggage = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="SaveBaggage()"
            >
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog Temperature -->
    <v-dialog v-model="dialogAlcoholAndTemperature" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Alcohol And Temperature Test</div>
            <div class="b-form" v-if="o_name != 'MUBADALA'">
              <v-text-field
                v-if="o_name != 'MUBADALA'"
                label="Alcohol Degree"
                type="number"
                v-model="at_value_test"
                suffix="mg."
              ></v-text-field>
            </div>
            <div class="b-form">
              <v-text-field
                label="Temperature Degree"
                type="number"
                v-model="at_value_temp"
                suffix="°C"
              ></v-text-field>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogAlcoholAndTemperature = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="SaveAlcoholAndTemperature()"
            >
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOffload" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Offload</div>

            <div class="b-form">
              <v-select
                class="hideMessage"
                v-model="offload"
                :items="itemsListOffload"
                label="Offload"
                item-text="ol_name"
                item-value="ol_id"
                return-object
              ></v-select>
            </div>
            <div class="b-form">
              <v-text-field
                v-model="offload_remark"
                label="Offload Remark"
              ></v-text-field>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogOffload = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="SaveOffload()"
            >
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog Confirm to Brief Checkin -->
    <v-dialog v-model="dialogConfirmToBriefCheckin" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm to Brief Check-in</div>
            <div class="t-des">
              Are you sure you want to to <br />Brief Check-in?
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogConfirmToBriefCheckin = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="ConfirmToBriefCheckin()"
            >
              <span class="T-size-18">Confirm</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { differenceInDays, format, addHours } from "date-fns";
import uploadBlob from "../utils/uploadBlob";
import Skytrac from "../components/Skytrac";
import DeclareForm from "../components/checkin-form/DeclareForm.vue";
import PrivacyNotice from "../components/pdpa/CompPrivacyNotice.vue";
import ConsentForm from "../components/pdpa/CompConsentForm.vue";

export default {
  data: () => ({
    isCheckin: false,
    fullname: "",
    prohibitedDrugs1: "",
    prohibitedDrugs2: "",
    prohibitedDrugs3: "",
    prohibitedDrugs4: "",

    alcoholBeverages1: "",
    alcoholBeverages2: "",
    alcoholBeverages3: "",
    alcoholBeverages4: "",

    //Add
    dialogInputRFID: false,
    dialogInputBodyWeight: false,
    dialogSignComplete: false,
    briefCheckin: false,
    briefCheckinTime: false,

    dialogConfirmToBriefCheckin: false,
    FirstTimeOffshore: "",
    dialogRemark: false,
    alcoholLimit: null,
    tempLimit: null,
    checkInDateTime: null,
    dialogSaveComplete: false,

    checkbox1: false,
    checkbox2: false,

    dialogEvent: false,
    dialogSave: false,
    dialogConfirm: false,
    loading: false,
    dialogBaggage: false,
    dialogAlcohol: false,
    dialogAlcoholAndTemperature: false,
    dialogSeat: false,
    dialogCancelSeat: false,
    dialogOffload: false,
    modeEvent: "",

    mode: "",
    flightno: "",
    idFlight: "",
    idPass: "",
    u_firstname: "",
    u_lastname: "",
    c_name: "",
    u_emergency_contact: "",
    u_emergency_relation: "",
    u_emergency_telephone: "",
    u_gender: "",
    u_safety_training: "",
    u_medical_checkup: "",
    u_telephone: "",
    ref_id: "",
    p_body_weight: "",
    p_seat_no: "",
    p_rfid: "",
    offload: "",
    offload_remark: "",
    bg_weight: "",
    at_statusNow: null,
    at_report_by: "",
    at_value_test: "",
    at_value_date: "",
    at_value_temp: "",
    at_status: "",
    Dayleft_safety_training: "",
    Dayleft_medical_checkUp: "",
    chooseItemLegs: null,
    chooseItemLegIndex: null,
    chooseItemBaggage: null,
    chooseItemBaggageIndex: null,
    ArrDataPassenger: [],
    itemsDataBaggage: [],
    ArrlistAddBaggage: [],
    itemsListOffload: [],
    itemsDataCompany: [],
    seatNo: null,
    arrSeatSelect: null,
    seats: [
      { key: 0, SeatNo: "A", Status: false, class: "" },
      { key: 1, SeatNo: "B", Status: false, class: "" },
      { key: 2, SeatNo: "C", Status: false, class: "" },
      { key: 3, SeatNo: "D", Status: false, class: "" },
      { key: 4, SeatNo: "E", Status: false, class: "" },
      { key: 5, SeatNo: "F", Status: false, class: "" },
      { key: 6, SeatNo: "G", Status: false, class: "" },
      { key: 7, SeatNo: "H", Status: false, class: "" },
      { key: 8, SeatNo: "I", Status: false, class: "" },
      { key: 9, SeatNo: "L", Status: false, class: "" },
      { key: 10, SeatNo: "M", Status: false, class: "" },
      { key: 11, SeatNo: "N", Status: false, class: "" },
    ],
    itemsDataAlcohol: [],
    ArrlistAddAlcohol: [],
    userdata: "",
    options: {
      penColor: "#00f",
    },
    //Add
    u_idcard_no: "",

    terminalID: 0,
    o_name: "",
    destination: "",
    skipPDPA:false,

    itemsDeclared: [
      { id: 1, name: "ยาสามัญ" },
      { id: 2, name: "พาราเซตามอล" },
      { id: 3, name: "ยาความดัน" },
    ],
  }),
  props: [],
  components: {
    DeclareForm,
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    this.mode = this.$route.params.mode;
    this.idPass = this.$route.params.id;
    this.o_name = this.$route.params.o_name;
    this.destination = this.$route.params.destination;
    this.skipPDPA = this.$route.params.skip_pdpa;

    await this.SelectListOffload();
    await this.DataPassengerFromDB();

    //ให้ได้ FlightID มาก่อนจาก function ก่อนหน้า

    if(this.skipPDPA == "false"){
      await this.CheckCosentForm();
    }

    await this.DataBaggageFromDB();
    this.DataAlcoholTestFromDB();
    this.SeatPassenger();

    //Seat & RFID & Body weight
    await feathersClientUOA.service("passenger").on("patched", (passenger) => {
      for (let index = 0; index < this.seats.length; index++) {
        this.seats[index].class = "";
        this.seats[index].Status = null;
      }
      this.AllSeatPassenger(this.idFlight);
      this.SeatPassenger();
      this.DataPassengerFromDB();
    });
    //Baggage & RFID
    await feathersClientUOA.service("baggage").on("created", (passenger) => {
      this.DataBaggageFromDB();
    });
    await feathersClientUOA.service("baggage").on("patched", (passenger) => {
      this.DataBaggageFromDB();
    });
    await feathersClientUOA.service("baggage").on("removed", (passenger) => {
      this.DataBaggageFromDB();
    });
    //Alcohol and Temperature Test
    await feathersClientUOA
      .service("alcoholtest")
      .on("created", (passenger) => {
        this.DataAlcoholTestFromDB();
      });
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async CheckCosentForm() {
      var q = {};
      q.p_id = this.idPass;
      var resPass = await feathersClientUOA
        .service("viewcheckinpassenger")
        .find({ query: q });
      var user = resPass.data[0];

      var resUser = await feathersClientUOA.service("user").get(user.u_id);
      if (
        resUser.u_privacy_notice == null ||
        resUser.u_consent_general_personal_data == null ||
        resUser.u_consent_sensitive_personal_data == null
      ) {
        if (this.userdata.r_id != 7) {
          this.$router.push({
            path: `/privacynotice/${user.u_id}/${this.idPass}/${this.o_name}/${this.destination}/${this.idFlight}`,
          });
        }
      }
    },
    async DataPassengerFromDB() {
      try {
        var q = {};
        q.p_id = this.idPass;
        var res = await feathersClientUOA
          .service("viewcheckinpassenger")
          .find({ query: q });
        this.ArrDataPassenger = res.data[0];
        this.u_idcard_no = this.ArrDataPassenger.u_idcard_no;
        this.u_firstname = this.ArrDataPassenger.u_firstname;
        this.u_lastname = this.ArrDataPassenger.u_lastname;
        this.fullname = this.u_firstname + "  " + this.u_lastname;
        this.ref_id = this.ArrDataPassenger.ref_id;
        this.flightno = this.ArrDataPassenger.f_flight_no;
        this.c_name = this.ArrDataPassenger.c_name;
        this.flighttype = this.ArrDataPassenger.f_type_of_flight;
        this.u_emergency_contact = this.ArrDataPassenger.u_emergency_contact;
        this.u_emergency_relation = this.ArrDataPassenger.u_emergency_relation;
        this.u_emergency_telephone =
          this.ArrDataPassenger.u_emergency_telephone;
        this.u_telephone = this.ArrDataPassenger.u_telephone;
        this.u_safety_training = this.ArrDataPassenger.u_safety_training;
        this.u_medical_checkup = this.ArrDataPassenger.u_medical_checkup;
        this.p_body_weight = this.ArrDataPassenger.p_body_weight;
        this.p_rfid = this.ArrDataPassenger.p_rfid;
        this.p_seat_no = this.ArrDataPassenger.p_seat_no;
        this.idFlight = this.ArrDataPassenger.f_id;
        //this.o_name = this.ArrDataPassenger.o_name;
        this.FirstTimeOffshore = this.ArrDataPassenger.p_remark;
        //Add
        if (this.ArrDataPassenger.o_alcohol_limit == null) {
          this.alcoholLimit = 0.02;
        } else {
          this.alcoholLimit = parseFloat(
            this.ArrDataPassenger.o_alcohol_limit
          ).toFixed(2);
        }

        if (this.ArrDataPassenger.o_temp_limit == null) {
          this.tempLimit = 37.3;
        } else {
          this.tempLimit = parseFloat(
            this.ArrDataPassenger.o_temp_limit
          ).toFixed(2);
        }

        if (this.ArrDataPassenger.p_offload != null) {
          let offloadSelect = this.itemsListOffload.filter(
            (x) => x.ol_id == parseInt(this.ArrDataPassenger.ol_id)
          );
          this.offload = offloadSelect[0];
          this.offload_remark = this.ArrDataPassenger.p_offload_remark;
        } else {
          this.offload = this.itemsListOffload[0];
        }

        this.briefCheckin = this.ArrDataPassenger.p_brief_checkin;

        this.briefCheckinTime = this.ArrDataPassenger.p_brief_checkin_time;

        this.terminalID = this.ArrDataPassenger.tm_id;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }

      this.AllSeatPassenger(this.idFlight);
      this.DayLeft();
    },
    async DayLeft() {
      var today = new Date();
      var SafetyTraining = new Date(this.u_safety_training);
      var safety = differenceInDays(SafetyTraining, today);

      this.Dayleft_safety_training = safety;

      var MedicalCheckUp = new Date(this.u_medical_checkup);
      var medical = differenceInDays(MedicalCheckUp, today);

      this.Dayleft_medical_checkUp = medical;
    },
    async SelectListOffload() {
      try {
        var res = await feathersClientUOA.service("offload").find({});
        this.itemsListOffload = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async DataBaggageFromDB() {
      this.ArrlistAddBaggage = [];
      this.itemsDataBaggage = [];

      try {
        var q = {};
        q.p_id = this.idPass;
        q.retire = "0";
        var res = await feathersClientUOA.service("baggage").find({ query: q });
        this.itemsDataBaggage = res.data;
        this.itemsDataBaggage.forEach((element) => {
          var newArrayBinding = {};
          newArrayBinding["Idflightbaggage"] = element.bg_id;
          newArrayBinding["bg_weight"] = parseFloat(element.bg_weight).toFixed(
            2
          );
          this.ArrlistAddBaggage.push(newArrayBinding);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SaveBaggage() {
      if (this.bg_weight != "") {
        if (this.modeEvent == "Add") {
          // var newArrayBinding = {};
          // newArrayBinding["Idflightbaggage"] = null;
          // newArrayBinding["bg_weight"] = this.bg_weight;
          //this.ArrlistAddBaggage.push(newArrayBinding);
          let DataAdd = {
            p_id: this.idPass,
            bg_weight: this.bg_weight,
            retire: 0,
          };
          this.CreateBaggage(DataAdd);
        } else {
          // var newArrayBinding = {};
          // newArrayBinding["Idflightbaggage"] = this.ArrlistAddBaggage[
          //   this.chooseItemBaggageIndex
          // ].Idflightbaggage;
          // newArrayBinding["bg_weight"] = this.bg_weight;
          // this.ArrlistAddBaggage[this.chooseItemBaggageIndex] = newArrayBinding;
          let dataUpdate = {
            bg_weight: this.bg_weight,
            retire: 0,
          };
          this.UpdateBaggagetodb(
            this.chooseItemBaggage.Idflightbaggage,
            dataUpdate
          );
        }
      }
      //this.SumDataTotal();
      this.dialogBaggage = false;
    },
    async addItemBaggage() {
      this.modeEvent = "Add";
      this.dialogBaggage = true;
      this.bg_weight = "";
    },
    async EditItemBaggage(itemBaggage, index) {
      this.modeEvent = "Edit";

      this.dialogBaggage = true;
      this.bg_weight = itemBaggage.bg_weight;
      this.chooseItemBaggage = itemBaggage;
      this.chooseItemBaggageIndex = index;
    },
    async deleteItemBaggage(itemBaggage) {
      // const index = this.ArrlistAddBaggage.indexOf(itemBaggage);
      // if (index > -1) {
      //   this.ArrlistAddBaggage.splice(index, 1);
      // }

      //Edit
      await feathersClientUOA
        .service("baggage")
        .remove(itemBaggage.Idflightbaggage);
    },
    // async SaveEditToDB() {
    //   let DataEditPassenger = {
    //     p_seat_no: this.seatNo,
    //     p_rfid: this.p_rfid,
    //     p_body_weight: this.p_body_weight,
    //   };

    //   try {
    //     var res = await feathersClientUOA
    //       .service("passenger")
    //       .patch(this.idPass, DataEditPassenger);
    //   } catch (error) {
    //     console.log(error);
    //   }

    //   this.SaveAlcoholEditToDB();

    //   this.SumBaggage();
    //   this.dialogSaveComplete = true;
    // },
    async SumBaggage() {
      let weight = this.ArrlistAddBaggage.reduce(function (prev, cur) {
        return parseFloat(prev) + parseFloat(cur.bg_weight);
      }, 0);

      var baggageWeight = weight;

      try {
        let CalBaggage = {
          p_baggage_weight: baggageWeight,
        };
        var res = await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, CalBaggage);
      } catch (error) {
        console.log(error);
      }
    },

    async UpdateBaggagetodb(Idbaggage, dataUpdate) {
      try {
        await feathersClientUOA.service("baggage").patch(Idbaggage, dataUpdate);
      } catch (error) {
        console.log(error);
      }
    },
    async CreateBaggage(DataAdd) {
      try {
        console.log(DataAdd);
        var res = await feathersClientUOA.service("baggage").create(DataAdd);
        console.log(res);
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    },
    async GotoListCheckin() {
      //this.$router.go(-1);
      this.$router.push({ path: `/checkindetail/${this.idFlight}` });
    },
    async SaveOffload() {
      var dataUpdate = {
        p_offload: "1",
        ol_id: this.offload.ol_id,
        p_offload_remark: this.offload_remark,
      };

      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }

      this.dialogOffload = false;
      //this.$router.go(-1);
      this.$router.push({ path: `/checkindetail/${this.idFlight}` });
    },
    async SeatPassenger() {
      var q = { p_id: this.idPass, pt_id: 1 };
      var res = await feathersClientUOA
        .service("viewcheckinpassenger")
        .find({ query: q });
      this.seatNo = res.data[0].p_seat_no;

      for (let index = 0; index < this.seats.length; index++) {
        if (this.seats[index].SeatNo == this.seatNo)
          this.seats[index].class = "select";
      }
    },
    async AllSeatPassenger(id) {
      var q = { f_id: id, pt_id: 1 };
      var res = await feathersClientUOA
        .service("viewcheckinpassenger")
        .find({ query: q });
      this.arrSeatSelect = res.data;
      for (let index = 0; index < this.seats.length; index++) {
        this.arrSeatSelect.forEach((element) => {
          if (element.p_seat_no == this.seats[index].SeatNo) {
            this.seats[index].Status = true;

            if (this.seatNo == element.p_seat_no) {
              this.seats[index].Status = false;
            }
          }
        });
      }
    },
    async SelectSeat(data, seat) {
      for (let index = 0; index < this.seats.length; index++) {
        this.seats[index].class = "";
      }
      this.seats[data].class = "select";

      this.seatNo = seat;
      this.SaveSeatToDB();
    },
    async SaveSeatToArray() {
      for (let index = 0; index < this.seats.length; index++) {
        if (this.seats[index].class == "select")
          this.seatNo = this.seats[index].SeatNo;
      }
      await this.SaveSeatToDB();
      this.dialogSeat = false;
    },
    async SaveSeatToDB() {
      let dataUpdate = {
        p_seat_no: this.seatNo,
      };
      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }
    },
    async DataAlcoholTestFromDB() {
      try {
        this.ArrlistAddAlcohol = [];
        var q = {};
        q.p_id = this.idPass;
        q.retire = "0";
        var res = await feathersClientUOA
          .service("alcoholtest")
          .find({ query: q });
        this.itemsDataAlcohol = res.data;

        this.itemsDataAlcohol.forEach((element) => {
          var newArrayBinding = {};
          newArrayBinding["at_id"] = element.at_id;
          newArrayBinding["at_status"] = element.at_status;
          newArrayBinding["at_report_by"] = element.at_report_by;
          newArrayBinding["at_value_test"] = parseFloat(
            element.at_value_test
          ).toFixed(2);
          newArrayBinding["at_value_date"] = format(
            new Date(element.at_value_date),
            "dd-MM-yyyy HH:mm"
          );
          newArrayBinding["at_value_temp"] = parseFloat(
            element.at_value_temp
          ).toFixed(2);
          this.ArrlistAddAlcohol.push(newArrayBinding);
        });

        var q = {};
        q.c_id = this.ArrDataPassenger.c_id;

        var res = await feathersClientUOA.service("company").find({ query: q });
        this.itemsDataCompany = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItemAlcohol() {
      this.at_value_test = "";
      this.at_value_temp = "";
      this.dialogAlcoholAndTemperature = true;
    },
    async SaveAlcoholAndTemperature() {
      this.ArrlistAddAlcohol = [];
      if (this.o_name == "MUBADALA") {
        if (this.at_value_temp != "") {
          var DateTime = format(new Date(), "dd-MM-yyyy HH:mm");
          let temp_limit = this.tempLimit;

          let status = "";
          let temp = parseFloat(this.at_value_temp);

          if (temp <= temp_limit) {
            status = 1;
            this.at_statusNow = true;
          } else {
            status = 0;
            this.at_statusNow = false;
          }

          var newArrayBinding = {};
          newArrayBinding["at_id"] = null;
          newArrayBinding["at_status"] = status;
          newArrayBinding["at_report_by"] = this.userdata.FullName;
          newArrayBinding["at_value_test"] = this.at_value_test;
          newArrayBinding["at_value_date"] = DateTime;
          newArrayBinding["at_value_temp"] = parseFloat(
            this.at_value_temp
          ).toFixed(2);

          this.ArrlistAddAlcohol.push(newArrayBinding);
        }
      } else {
        if (this.at_value_test != "" && this.at_value_temp != "") {
          var DateTime = format(new Date(), "dd-MM-yyyy HH:mm");
          // let alcohol_limit = this.itemsDataCompany[0].c_alcohol_limit;
          //let temp_limit = this.itemsDataCompany[0].c_temp_limit;
          let alcohol_limit = this.alcoholLimit;
          let temp_limit = this.tempLimit;

          let status = "";
          let test = parseFloat(this.at_value_test);
          let temp = parseFloat(this.at_value_temp);

          if (test <= alcohol_limit && temp <= temp_limit) {
            status = 1;
            this.at_statusNow = true;
          } else {
            status = 0;
            this.at_statusNow = false;
          }

          var newArrayBinding = {};
          newArrayBinding["at_id"] = null;
          newArrayBinding["at_status"] = status;
          newArrayBinding["at_report_by"] = this.userdata.FullName;
          newArrayBinding["at_value_test"] = parseFloat(
            this.at_value_test
          ).toFixed(2);
          newArrayBinding["at_value_date"] = DateTime;
          newArrayBinding["at_value_temp"] = parseFloat(
            this.at_value_temp
          ).toFixed(2);

          this.ArrlistAddAlcohol.push(newArrayBinding);
        }
      }
      await this.SaveAlcoholEditToDB();

      this.dialogAlcoholAndTemperature = false;
    },
    async SaveAlcoholEditToDB() {
      this.ArrlistAddAlcohol.forEach((element) => {
        if (element.at_id == null) {
          let DataAdd = {
            p_id: this.idPass,
            at_report_by: element.at_report_by,
            at_value_test: element.at_value_test,
            at_value_date: new Date(),
            at_value_temp: element.at_value_temp,
            at_status: element.at_status,
            retire: 0,
          };
          if (element.at_status == 1) {
            this.UpdatePassengertodb();
          }
          this.CreateAlcohol(DataAdd);
        }
      });
    },
    async CreateAlcohol(DataAdd) {
      console.log(JSON.stringify(DataAdd));
      try {
        var res = await feathersClientUOA
          .service("alcoholtest")
          .create(DataAdd);
      } catch (error) {
        console.log(error);
      }
    },
    async UpdatePassengertodb() {
      try {
        var res = await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, { as_id: 4 });
      } catch (error) {
        console.log(error);
      }
    },
    async UndoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    async SaveSignatureToDB() {
      const dataURL = this.$refs.signaturePad.saveSignature();
      // console.log(
      //   "SaveSignatureToDB dataURL.data.length => " + dataURL.data.length
      // );
      //console.log(JSON.stringify(dataURL.data));
      const resSignature = await uploadBlob(dataURL.data);
      try {
        let DataCheckin = {
          p_checkin_signature: resSignature.data.Url,
          p_checkin: "1",
          p_checkin_date: this.checkInDateTime,
          p_checkin_by: this.userdata.FullName,
        };
        var res = await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, DataCheckin);

        this.GotoListCheckin();
      } catch (error) {
        console.log(error);
      }
    },

    Back() {
      //this.$router.go(-1);
      this.$router.push({ path: `/checkindetail/${this.idFlight}` });
    },

    CheckInDateTime() {
      this.checkInDateTime = new Date().toISOString();
    },

    async SaveRemarkFirstTimeOffshore() {
      let dataUpdate = {
        p_remark: this.FirstTimeOffshore,
      };
      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }
      this.dialogRemark = false;
    },

    async SaveBodyWeight() {
      let dataUpdate = {
        p_body_weight: this.p_body_weight,
      };
      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }
      this.dialogInputBodyWeight = false;
    },

    CancelSaveBodyWeigh() {
      this.dialogInputBodyWeight = false;
      this.p_body_weight = null;
      this.DataPassengerFromDB();
    },

    async SaveRFID() {
      let dataUpdate = {
        p_rfid: this.p_rfid,
      };

      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }
      this.dialogInputRFID = false;
    },

    CancelSaveRFID() {
      this.dialogInputRFID = false;
      this.p_rfid = null;
      this.DataPassengerFromDB();
    },

    BriefCheckin(rfid) {
      this.dialogConfirmToBriefCheckin = true;
    },

    async ConfirmToBriefCheckin() {
      var result = await feathersClientUOA
        .service("briefcheckincommand")
        .get(this.p_rfid, { query: { terminalID: this.terminalID } });
      this.dialogConfirmToBriefCheckin = false;
      if (result > 0) {
        this.DataPassengerFromDB();
      } else {
        //Flight status = 3 (Opened) ต้อง Status = 4 (Closed) ถึง Checkin ห้อง Brief ได้
      }
    },

    OpenDialogRFID() {
      this.dialogInputRFID = true;
      //document.getElementById("txtRFID").focus();
    },

    getBase64Image1(strDataURI) {
      var img = new Image();
      img.src = strDataURI;

      // Create canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      // Set width and height
      canvas.width = img.width;
      canvas.height = img.height;
      // Draw the image
      ctx.drawImage(img, 0, 0);

      return canvas.toDataURL("image/jpeg");
    },

    getBase64Image(img) {
      // Create an empty canvas element
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      // Copy the image contents to the canvas
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Get the data-URL formatted image
      // Firefox supports PNG and JPEG. You could check img.src to
      // guess the original format, but be aware the using "image/jpg"
      // will re-encode the image.
      var dataURL = canvas.toDataURL("image/png");

      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    },

    async PrintSignature() {
      let fullname = this.u_firstname + "  " + this.u_lastname;
      let passportNo = this.u_idcard_no;
      let data = this.ArrlistAddAlcohol[this.ArrlistAddAlcohol.length - 1];

      let alcoholtest;
      let temp;
      if (data != undefined) {
        alcoholtest = data.at_value_test;
        temp = data.at_value_temp;
      }

      if(alcoholtest == -1){
        alcoholtest = "0.00";
      }

      var pdfMake = require("../../public/js/pdfmake");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("../../public/js/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew-Bold.ttf",
          italics: "THSarabunNew-Italic.ttf",
          bolditalics: "THSarabunNew-BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var docDefinition = {
        images: {
          signatureImage: this.ArrDataPassenger.p_checkin_signature,
        },
        content: [
          {
            style: "tableHeader",
            table: {
              headerRows: 1,
              widths: ["*", "auto", "*"],
              body: [
                [
                  {
                    text: "",
                    alignment: "left",
                  },
                  {
                    text: "Passenger’s Acknowledge form",
                    fontSize: 16,
                    bold: true,
                    style: "tableHeader",
                    margin: [0, 15, 0, 0],
                  },
                  {
                    text: "",
                    alignment: "right",
                  },
                ],
                ["", "", ""],
              ],
            },
            layout: "headerLineOnly",
          },
          {
            style: "tableDetail",
            table: {
              headerRows: 1,
              widths: ["auto", "*", "auto", "*"],
              body: [
                [
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: "Passenger Name : ",
                    alignment: "left",
                    style: "header",
                    border: [false, false, false, false],
                  },
                  {
                    text: fullname,
                    alignment: "left",
                    border: [false, false, false, true],
                  },
                  {
                    text: "ID/Passport No : ",
                    alignment: "left",
                    style: "header",
                    border: [false, false, false, false],
                  },
                  {
                    text: passportNo,
                    alignment: "left",
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    text: "Alcohol : ",
                    alignment: "right",
                    style: "header",
                    border: [false, false, false, false],
                  },
                  {
                    text: alcoholtest,
                    alignment: "left",
                    border: [false, false, false, true],
                  },
                  {
                    text: "Temperature : ",
                    alignment: "right",
                    style: "header",
                    border: [false, false, false, false],
                  },
                  {
                    text: temp,
                    alignment: "left",
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
          },
          {
            style: "tableAgreement",
            table: {
              headerRows: 1,
              widths: ["auto", "*"],
              body: [
                [
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    image:
                      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAdklEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5KmoQYc0DVEMfxjqiNcAUv6foYZYDQjleDRoYlWOU0Mdw2+GECzKcWpoAZK/GUIxlONxUjNYC7pyvJ5uBrNrSIm4JgzltE0aOORJTt4kZ6BBBAADNZmOfmKY6QAAAABJRU5ErkJggg==",
                    width: 13,
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "ข้าพเจ้าได้อ่านและเข้าใจในข้อจำกัดเกี่ยวกับสิ่งของอันตรายที่ต้องห้ามสำหรับการขนส่งทางอากาศในกระเป๋าหรือสัมภาระที่ผ่านการตรวจสอบแล้ว",
                    alignment: "left",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "(I hereby, acknowledged that I am understood and aware on the restrictions and limitations of transportation of Dangerous Goods by air on all carried and checked-in baggage or cargo.)",
                    alignment: "left",
                    border: [false, false, false, false],
                  },
                ],
                // [
                //   {
                //     image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAdklEQVQ4y2NgGLTAg+Exw3888DFQBQp4hFc5CD5C1QASwgcw5KmoQYc0DVEMfxjqiNcAUv6foYZYDQjleDRoYlWOU0Mdw2+GECzKcWpoAZK/GUIxlONxUjNYC7pyvJ5uBrNrSIm4JgzltE0aOORJTt4kZ6BBBAADNZmOfmKY6QAAAABJRU5ErkJggg==",
                //     width:13,
                //     alignment: "center",
                //     border: [false, false, false, false],
                //   },
                //   {
                //     text: "อ้างตามประกาศฉบับที่ 7 ออกตามความใน พรบ คุ้มครองแรงงาน พ.ศ. 2541 ข้าพเจ้าขอรับรองว่า ข้าพเจ้ามีวันทำงานกลางทะเลไม่เกิน 28 วันและมีวันหยุดไม่ต่ำกว่ากึ่งหนึ่งของวันทำงานต่อเนื่อง",
                //     alignment: "left",
                //     border: [false, false, false, false],
                //   },
                // ],
                // [
                //   {
                //     text: "",
                //     alignment: "center",
                //     border: [false, false, false, false],
                //   },
                //   {
                //     text: "(Refer to Ministry regulation No.7 follow Labor Protect Act B.E.2541. I hereby, certify that my working days at offshore are not more than 28 days and I have minimum day off not less than half of consecutive working days.)",
                //     alignment: "left",
                //     border: [false, false, false, false],
                //   },
                // ],
              ],
            },
          },

          {
            image: "signatureImage",
            width: 500,
            alignment: "center",
          },
          {
            style: "tableSign",
            table: {
              headerRows: 1,
              widths: ["auto", "*"],
              body: [
                [
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "Passenger’s signature_______________________",
                    alignment: "right",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text:
                      "Check-in date " +
                      format(
                        new Date(this.ArrDataPassenger.p_checkin_date),
                        "dd/MM/yyy"
                      ) +
                      " time " +
                      format(
                        new Date(this.ArrDataPassenger.p_checkin_date),
                        "HH:mm"
                      ),
                    alignment: "right",
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
          },
        ],
        styles: {},
        defaultStyle: {
          font: "THSarabunNew",
        },
      };

      pdfMake
        .createPdf(docDefinition)
        .download("PassengerIsAcknowledgeForm.pdf");
    },

    async RFIDScanner(channel) {
      //Step 1 update channel เดิมเป็น null
      try {
        await feathersClientUOA
          .service("clear-channel-rfid-scanner")
          .patch(channel, {});
      } catch (error) {
        console.log(error);
      }

      //Setp 2 update channel
      try {
        let dataUpdate = {
          p_rfid_scanner: channel,
        };
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
      } catch (error) {
        console.log(error);
        s;
      }
    },

    SaveForm() {
      alert(this.prohibitedDrugs1 + " " + this.prohibitedDrugs2);
    },

    async OpenDialogSelectSeat() {
      await this.SeatPassenger();
      await this.AllSeatPassenger(this.idFlight);
      this.dialogSeat = true;
    },
    async ClearSeat(seatNo) {
      for (let index = 0; index < this.seats.length; index++) {
        this.seats[index].class = "";
      }
      let dataUpdate = {
        p_seat_no: null,
      };
      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.idPass, dataUpdate);
        await this.SeatPassenger();
        await this.AllSeatPassenger(this.idFlight);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
a:link {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}
</style>
