<template>
  <v-card
    id="dialog"
    class="TF type2"
    style="color: white; height: 100%; overflow: scroll; width: 100%"
  >
    <div class="b-content B-carddetail">
      <div class="B-dialogform">
        <div class="t-namepage highlight">
          Privacy Notice (ประกาศความเป็นส่วนตัว)
        </div>
        <div class="b-form">
          <div style="padding-left: 12px">
          <br/>
            <p style="text-align: center; font-weight: bold">Privacy Notice</p>
            <p style="text-align: center; font-weight: bold">
              (ประกาศความเป็นส่วนตัว)
            </p>
            <p>
              We, United Offshore Aviation Co., Ltd.
              <span class="highlight">(“UOA”),</span> need to collect, use,
              disclose, transfer and process your following general personal
              data <span class="highlight">(“General Personal Data”)</span> and
              sensitive personal data
              <span class="highlight">(“Sensitive Personal Data”)</span>
              (collectively referred to as
              <span class="highlight">“Personal Data”)</span>
            </p>

            <table>
              <tr>
                <th>General Personal Data</th>
                <th>Sensitive Personal Data</th>
              </tr>
              <tr>
                <td>
                  • Name and surname<br />•	Identification Card No or Passport No.<br/>•	Nationality<br/>• Contact numbers<br />• Body Weight
                </td>
                <td>
                  • Alcohol Test<br />• Temperature Test<br /><br /><br /><br />
                </td>
              </tr>
            </table>
            <p></p>

            <p>
              Your Personal Data will be collected, used, disclosed, transferred,
              or processed for the purposes of:
            </p>
            <div style="padding-left: 40px">
              •
              <span style="padding-left: 10px"
                >Arranging the helicopter Service Business</span
              >
            </div>
            <div style="padding-left: 40px">
              •
              <span style="padding-left: 10px"
                >Arranging the helicopter service safety measurement</span
              >
            </div>
            <div style="padding-left: 40px">
              •
              <span style="padding-left: 10px"
                >Sharing Personal Data within your employer</span
              >
            </div>
          </div>

          <div class="box-S4 flex-between-center noPadding">
            <div class="B-carddetail">
              <div class="highlight">Necessity of Personal Data processing</div>
              <p>
                Your Personal Data are necessary for the above-mentioned
                purposes.
              </p>

              <div class="highlight">
                Impact if UOA does not get your Personal Data
              </div>
              <p>
                Without the Personal Data requested above, UOA will not be able
                to arrange all activities mentioned in the above proposes while
                the Company will be unable to serve or manage or provide you
                with proper process to facilitate you as our customer.
              </p>

              <div class="highlight">
                Disclosure or transfer to third parties
              </div>
              <p>
                UOA will keep your Personal Data strictly confidential and will
                not disclose it to any third parties unless UOA obtain your
                prior express consent or receive a specific order from competent
                authority.
              </p>

              <p>
                In case where the Personal Data will be transferred outside
                Thailand, UOA will comply with the Personal Data Protection Act,
                B.E. 2562
                <span class="highlight highlight">(“PDPA”)</span> including but
                not limited to ministerial regulations, notifications,
                interpretations, or other regulations issued under the PDPA.
              </p>

              <div class="highlight">Retention period</div>
              <p>
                Your Personal Data will be retained for a period of ten (10)
                years after the helicopter service between UOA and your employer
                has ended.
              </p>
              <p>
                UOA undertakes that your Personal Data will be retained in a
                secure location, accessible only to authorised persons and will
                be immediately destroyed once the retention period has elapsed.
              </p>

              <div class="highlight">
                Your rights regarding the Personal Data under the PDPA
              </div>
              <p>
                You are entitled to all the rights granted under the PDPA,
                unless there are circumstances that UOA may need to reject your
                request to exercise such rights. Importantly, you have a right
                to withdraw your consent. If you change your mind about how you
                would like UOA to process your Personal Data, you can tell us
                anytime.
              </p>

              <p>
                Please refer to Link of <a target="_blank" href="https://prodigyfly.com/PDPA/Personal%20data%20protection%20policy_UOA_30052022.pdf" style="display: inline; color: white;">
                <span class="highlight"
                  >UOA’s Personal Data Privacy Policy</span
                ></a>
                for details of your rights and our consent withdrawal process.
              </p>

              <div class="highlight">Contact us</div>
              <p>
                If you would like any further information or to exercise any of
                your rights, please contact:
              </p>

              <table>
                <tr>
                  <td>Name</td>
                  <td>Capt. Noppadol Chinpumiwasana (Flight Operations Manager)</td>
                </tr>
                <tr>
                  <td style="width: 25%">Contact information</td>
                  <td>
                    noppadol@uoathai.com (Tel : 074322577-8)
                  </td>
                </tr>
              </table>

              <br />
              <v-checkbox
                @change="ChangePrivacyNotice(privacyNotice)"
                v-model="privacyNotice"
                hide-details
                label="I have read and understood the above details regarding the collection, use, disclosure, transfer and processing of my Personal Data, as well as being aware of my rights as the owner of Personal Data in accordance with the PDPA and other related laws."
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="box-btn">
        <v-btn
          class="theme-btn-even btn-save Bsize100"
          text
          v-show="privacyNotice == true"
          @click="PrivacyNotice()"
        >
          <span class="T-size-18">Confirm</span>
        </v-btn>
      </div>
    </div>


<v-dialog v-model="dialogConfirmmPersonal" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title">Confirm Personal Information</div>
            <div class="t-des">
              <div>
                Are you
              </div>
              <div>{{ this.user.u_tital }} {{ this.user.u_firstname }}  {{ this.user.u_lastname }}?</div>
            </div>
            <div class="box-S4"></div>
          </div>
          <div class="box-btn">
            <v-btn 
              :to="{ name: 'CheckinDetail', params: { id: this.idflight } }"
            class="theme-btn-even btn-cancel Bsize100" text>
              <span class="T-size-18">NO</span>
            </v-btn>
            <v-btn
              @click="dialogConfirmmPersonal = false"
              class="theme-btn-even btn-delete Bsize100"
              text
            >
              <span class="T-size-18">YES</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>


  </v-card>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
export default {
  data: () => ({
    user:{
      u_tital: "",
      u_firstname: "",
      u_lastname: "",
    },
    dialogConfirmmPersonal: false,
    privacyNotice: false,
  }),
  props: ["userId","idflight"],
  components: {},
  async mounted() {
    this.user = await feathersClientUOA.service("user").get(this.userId);
    this.dialogConfirmmPersonal = true;
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    ChangePrivacyNotice(data) {
      this.privacyNotice = data;
    },
    async PrivacyNotice() {
      if (this.privacyNotice) {
        try {
          let dataEdit = {
            u_privacy_notice: true,
            u_privacy_notice_time: new Date(),
            u_privacy_notice_version:1,
          };
          var res = await feathersClientUOA
            .service("user")
            .patch(this.userId, dataEdit);
        } catch (error) {
          console.log(error);
        }
        this.$emit("PrivacyNotice", true);
      } else {
        alert("Error");
      }
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table,
td,
th {
  border: 2px solid white;
  padding-left: 10px;
}

.highlight {
  font-weight: bold;
  display: inline;
}
</style>>

